<template>
  <v-dialog
    v-bind="$attrs"
    v-model="show"
    :max-width="dialogWidth"
    :content-class="checkSmAndDown ? 'rounded-12 cardFilterBg' : 'rounded-20 cardFilterBg'"
    persistent
    outlined
    transition="scroll-y-transition"
    v-if="filtersData.length"
    overlay-color="overlayColor"
  >
    <v-row no-gutters class="pb-10" :class="checkSmAndDown ? 'pt-4 px-4' : 'pt-15 px-15'">
      <v-col
        class="pa-4 pa-sm-5"
        v-for="(filter, index) of filtersData"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <div class="font-normal nameField--text mb-5">{{ filter.name }}</div>
  
        <SelectSearchHeader
          v-if="filter.filter === 'callers'"
          v-model="filter.current"
          :search-input.sync="callerSearch"
          :items="filter.items"
          item-text="name"
          item-value="id"
          :loading="filter.loading"
          :menu-props="{ nudgeTop: -5, contentClass: 'select-search-header' }"
          :no-data-text="getNoDataText"
          type="number"
          return-object
          clearable
          @update:search-input="checkSearchFilterValueByCaller($event, index, filter.current)"
        />

        <SelectSearchHeader
          v-else
          v-model="filter.current"
          :items="filter.items"
          item-text="name"
          item-value="id"
          :loading="filter.loading"
          :menu-props="{ nudgeTop: -5, contentClass: 'select-search-header' }"
          @focus="loadFilterData(index)"
          return-object
          clearable
        />
      </v-col>

      <v-col class="pa-4 pa-sm-5" cols="12" sm="6" md="4" lg="3">
        <div class="font-normal nameField--text mb-5">Duration less than, sec</div>
  
        <FormField
          v-model="durationLessThan"
          classes="header-input"
          type="number"
          checkBg
          customBg
        />
      </v-col>
      
      <v-col class="pa-4 pa-sm-5" cols="12" sm="6" md="4" lg="3">
        <div class="font-normal nameField--text mb-5">Duration more than, sec</div>
  
        <FormField
          v-model="durationMoreThan"
          classes="header-input"
          type="number"
          checkBg
          customBg
          @keypress="onlyNumbers"
        />
      </v-col>
  
      <v-col
        v-if="isManager"
        class="pa-4 pa-sm-5"
        cols="12"
        sm="6"
        md="4"
        lg="3"
        align-self="end"
      >
        <div class="font-normal nameField--text mb-5">Tags</div>
  
        <SelectForFilterByTags
          v-model="currentFiltersByTags"
          :items="currentFiltersByTags"
          item-text="name"
          item-value="id"
          :menu-props="{ nudgeTop: -52, contentClass: 'select-search-header', closeOnContentClick: hoverAddTagBtn }"
          no-data-text="No filters by tags"
          return-object
          multiple
          clearable
          @click:clear="clearFiltersByTags"
          @input="removeTagFilter"
        >
          <template #prepend-item>
            <div class="mx-6 my-2">
              <v-hover #default="{ hover }">
                <ActionButton width="100%" height="46" :hoverBtn="hover" @click="addTagFilter" @isHover="toggleCloseOnClick">
                  Add filter by tags
                </ActionButton>
              </v-hover>
            </div>
          </template>
          
          <template #selection="{ item, index }">
            <span class="nameField--text pr-4" v-if="index === 0">
              {{ currentFiltersByTags.length }} filter{{ currentFiltersByTags.length > 1 ? 's' : '' }} by tags
            </span>
          </template>
          
          <template #item="{ item }">
            <div class="d-flex justify-space-between align-center width--full">
              <div class="font-normal">{{ item.tagName }}: {{ item.name }}</div>
  
              <div><v-icon left>mdi-close</v-icon></div>
            </div>
          </template>
        </SelectForFilterByTags>
      </v-col>
    </v-row>
    
    <FilterByTags
      v-if="isManager"
      v-model="visibleFilterByTags"
      :treeUrlParams="treeUrlParams"
      :filtersByTags="filtersByTags"
      :params="params"
      @newFiltersByTags="updateFiltersByTags"
    />
    
    <div
      class="d-flex justify-end align-self-end filterAction pt-10"
      :class="checkSmAndDown ? 'pb-9 px-9' : 'pb-20 px-20'"
    >
      <CancelButton class="mr-8" @click="closeFilter">
        Cancel
      </CancelButton>
      <ActionButton @click="setFilter">
        Apply
      </ActionButton>
    </div>
  </v-dialog>
</template>

<script>
import SelectSearchHeader from '@/components/select/SelectSearchHeader'
import SelectForFilterByTags from '@/components/select/SelectForFilterByTags'
import FormField from '@/components/inputs/FormField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import FilterByTags from '@/components/expansion-panel/reporting/FilterByTags'

import { mapState, mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getFiltersNames, getFilterDataByName } from '@/api/reporting-routes'
import { getAllTreeUrlParams } from '@/api/integration-routes'
import { parseFiltersData, parseTreeUrlParams, parseTheFilterData } from '@/helper/reporting-helper'

export default {
  name: 'ReportingFilters',
  inheritAttrs: false,
  components: { SelectSearchHeader, SelectForFilterByTags, FormField, CancelButton, ActionButton, FilterByTags },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    visibleFilterByTags: false,
    hoverAddTagBtn: false,
    callerLoading: false,
    filtersData: [],
    treeUrlParams: [],
    currentFiltersByTags: [],
    filtersByTags: {},
    durationLessThan: null,
    durationMoreThan: null,
    tagValue: null,
    callerFilterTimer: null,
    callerSearch: null,
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    ...mapState({
      accountId: state => state.auth.accountId,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    checkSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    dialogWidth() {
      switch (true) {
        case this.$vuetify.breakpoint.xs: return 400
        case this.$vuetify.breakpoint.sm: return 600
        case this.$vuetify.breakpoint.md: return 940
        default: return 1200
      }
    },
    getNoDataText() {
      if (!this.callerSearch || this.callerSearch.toString().length < 4) {
        return 'Please enter at least 4 digits'
      }

      if (this.callerLoading) {
        return 'Loading...'
      }

      return 'No data available'
    },
  },
  methods: {
    async loadFiltersNames() {
      if (!this.treeUrlParams?.length) {
        const { success, payload, message } = await getFiltersNames()

        if (success) {
          if (Array.isArray(payload)) {
            this.filtersData = parseFiltersData(payload)
          }
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
      }
      
      this.parseFilters()
    },
    async loadTreeUrlParams() {
      if (!this.isManager) {
        return
      }
      
      if (!this.filtersData?.length) {
        const { success, payload, message } = await getAllTreeUrlParams()

        if (success) {
          if (!!Object.keys(payload).length) {
            this.treeUrlParams = parseTreeUrlParams(payload)
          }
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
      }
    },
    parseFilters() {
      this.filtersData.forEach(item => {
        const filterName = item.filter.slice(0, -1)
        if (this.filters[filterName]) {
          item.current = {
            id: this.filters[filterName].id,
            name: this.filters[filterName].name,
          }
          
          const checkIndex = item.items.findIndex(checkItem => checkItem.id === item.current.id)
          
          if (checkIndex === -1) {
            item.items.push(item.current)
          }
        } else {
          item.current = null
        }
      })
      
      this.durationLessThan = this.filters?.durationLessThan ? this.filters.durationLessThan.id : null
      this.durationMoreThan = this.filters?.durationMoreThan ? this.filters.durationMoreThan.id : null
      
      if (this.filters.hasOwnProperty('tags') && !!this.filters.tags && Object.keys(this.filters.tags).length) {
        this.updateFiltersByTags(this.filters.tags)
      } else {
        this.clearFiltersByTags()
      }
    },
    clearFiltersByTags() {
      this.filtersByTags = {}
      this.currentFiltersByTags = []
    },
    removeTagFilter(items) {
      if (!items.length) {
        this.clearFiltersByTags()
        return
      }
      
      Object.keys(this.filtersByTags).forEach(tagName => {
        Object.keys(this.filtersByTags[tagName]).forEach((itemName, i, arr) => {
          const currentItem = items.find(item => item.itemName === itemName)
          
          if (!currentItem) {
            if (arr.length === 1) {
              delete this.filtersByTags[tagName]
            } else {
              delete this.filtersByTags[tagName][itemName]
            }

            this.updateFiltersByTags(this.filtersByTags)
          }
        })
      })
    },
    toggleCloseOnClick(hover) {
      this.hoverAddTagBtn = hover
    },
    addTagFilter() {
      this.visibleFilterByTags = !this.visibleFilterByTags
    },
    updateFiltersByTags(newFiltersByTags) {
      this.filtersByTags = newFiltersByTags
      this.parseTagFilter(newFiltersByTags)
    },
    parseTagFilter(filters) {
      const addedTags = []
      Object.keys(filters).forEach(tagName => {
        Object.keys(filters[tagName]).forEach(itemName => {
          addedTags.push({
            tagName,
            itemName,
            name: filters[tagName][itemName].name,
            value: filters[tagName][itemName].id
          })
        })
      })
      
      this.currentFiltersByTags = addedTags
    },
    async loadFilterData(index) {
      this.filtersData[index].loading = true
      
      const { success, payload, message } = await getFilterDataByName({
        filterName: this.filtersData[index].filter,
        params: this.params
      })
      
      if (success) {
        if (Array.isArray(payload)) {
          this.filtersData[index].items = parseTheFilterData(payload)
        }
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.filtersData[index].loading = false
    },
    async checkSearchFilterValueByCaller(value, index, currentValue) {
      if (!value) {
        return
      }

      if (value.toString().length < 4) {
        return
      }

      if (!!currentValue && currentValue.name.toString() === value.toString()) {
        return
      }

      clearInterval(this.callerFilterTimer)

      this.callerFilterTimer = setInterval(() => {
        clearInterval(this.callerFilterTimer)
        this.loadFilterDataByCaller(value, index)
      }, 300)
    },
    async loadFilterDataByCaller(value, index) {
      this.callerLoading = true

      this.filtersData[index].loading = true

      const { success, payload, message } = await getFilterDataByName({
        filterName: this.filtersData[index].filter,
        params: {
          ...this.params,
          digits: value
        }
      })

      if (success) {
        if (Array.isArray(payload)) {
          this.filtersData[index].items = parseTheFilterData(payload)
        }
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.callerLoading = false
      this.filtersData[index].loading = false
    },
    setFilter() {
      const setFilters = {}
      this.filtersData.forEach(item => {
        const filterName = item.filter.slice(0, -1)
        setFilters[filterName] = item.current ? { ...item.current, filterName: item.name } : null
      })
      
      if (this.durationLessThan) {
        setFilters.durationLessThan = {
          filterName: 'Duration',
          id: +this.durationLessThan,
          name: `less than ${+this.durationLessThan}`
        }
      }
      
      if (this.durationMoreThan) {
        setFilters.durationMoreThan = {
          filterName: 'Duration',
          id: +this.durationMoreThan,
          name: `more than ${+this.durationMoreThan}`
        }
      }
      
      if (!!this.filtersByTags && !!Object.keys(this.filtersByTags).length) {
        setFilters.tags = this.filtersByTags
      } else {
        setFilters.tags = null
      }
      
      this.$emit('update:filters', setFilters)
      
      this.closeFilter()
    },
    deleteFilterValue(index) {
      this.filtersData[index].current = null
    },
    closeFilter() {
      this.show = false
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.loadFiltersNames()
          this.loadTreeUrlParams()
        } else {
          this.visibleFilterByTags = false
        }
      },
    },
    accountId: {
      handler(accountId) {
        if (!!accountId) {
          this.treeUrlParams = []
          this.filtersData = []
          this.loadFiltersNames()
          this.loadTreeUrlParams()
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.callerFilterTimer)
  },
}
</script>
