<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="rounded reset-btn resetBtnBg"
      :height="height"
      :ripple="false"
      depressed
    >
    <span
      class="font-normal"
      :class="hover ? 'resetBtnColorHover--text' : 'resetBtnColor--text'"
    >
      <slot></slot>
    </span>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'ResetButton',
  inheritAttrs: false,
  props: {
    height: {
      type: Number | String,
      default: 48
    }
  },
}
</script>