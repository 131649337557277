import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getCallLogData = params => axios.get(`${getAccountId()}/calls`, { params })
const getChartData = params => axios.get(`${getAccountId()}/calls/chart`, { params })
const getAggGroupNames = () => axios.get(`${getAccountId()}/calls/agg-groups`)
const getAggGroupData = params => axios.get(`${getAccountId()}/calls/aggregated`, { params })
const getDownloadCallLogCSV = params => axios.get(`${getAccountId()}/calls/csv`, { params })
const getDownloadAggCSV = params => axios.get(`${getAccountId()}/calls/aggregated/csv`, { params })

const getFiltersNames = () => axios.get(`${getAccountId()}/calls/filters`)
const getFilterDataByName = ({ filterName, params }) => axios.get(`${getAccountId()}/calls/filters/${filterName}`, { params })
const getTagValues = params => axios.get(`${getAccountId()}/calls/filters/tags`, { params })

const sendBlockNumber = formData => axios.post(`${getAccountId()}/numbers/blacklist`, formData)
const sendAdjustingCallPayment = ({ callId, formData }) => axios.patch(`${getAccountId()}/calls/${callId}/price`, formData)

const getAudioLink = id => axios.get(`${getAccountId()}/calls/${id}/recording`)

const stopLiveCall = callId => axios.delete(`${getAccountId()}/calls/${callId}/live`)
const stopLiveCallForBuyer = callId => axios.delete(`${getAccountId()}/buyer/calls/${callId}/live`)

export {
  getCallLogData, getChartData, getAggGroupNames, getAggGroupData, getDownloadCallLogCSV, getDownloadAggCSV, getFiltersNames, getFilterDataByName, getTagValues, sendBlockNumber, sendAdjustingCallPayment, getAudioLink, stopLiveCall, stopLiveCallForBuyer
}