<template>
  <v-btn
    outlined
    v-bind="$attrs"
    v-on="$listeners"
    class="rounded auto-refresh-btn"
    :class="{ 'auto-refresh-active': active }"
    :height="height"
    :ripple="false"
    @click="changeActive"
  >
    Auto Refresh
  </v-btn>
</template>

<script>
export default {
  name: 'AutoRefreshButton',
  inheritAttrs: false,
  props: {
    value: {
     type: Boolean,
     required: true
    },
    height: {
      type: Number | String,
      default: 48
    }
  },
  computed: {
    active: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    changeActive() {
      this.active = !this.active
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .v-btn {
    &.auto-refresh-btn {
      background: var(--v-autoRefreshBtnBg-base);
      border-color: var(--v-autoRefreshBorder-base);
      
      & > .v-btn__content {
        color: var(--v-autoRefreshBtnColor-base);
      }
  
      &:hover {
        border-color: var(--v-autoRefreshBorderHover-base)!important;
  
        & > .v-btn__content {
          color: var(--v-autoRefreshBtnColorHover-base)!important;
        }
      }
      
      &.auto-refresh-active {
        border-color: var(--v-autoRefreshBorderActive-base)!important;
        
        & > .v-btn__content {
          color: var(--v-autoRefreshBtnActiveColor-base)!important;
        }
  
        &:hover {
          border-color: var(--v-autoRefreshBorderActiveHover-base)!important;
        }
      }
    }
  }
}
</style>