<template>
  <div>
    <v-tabs v-model="tab" class="tableTab" mobile-breakpoint="0">
      <v-tab>Events</v-tab>
      <v-tab>Tags</v-tab>
    </v-tabs>
  
    <v-tabs-items touchless v-model="tab">
      <v-tab-item>
        <CallEventsTable :events="events"/>
      </v-tab-item>
    
      <v-tab-item>
        <CallTagsTable :tags="tags"/>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CallEventsTable from '@/components/expansion-panel/reporting/CallEventsTable'
import CallTagsTable from '@/components/expansion-panel/reporting/CallTagsTable'

export default {
  name: 'CallLogTabs',
  inheritAttrs: false,
  components: { CallEventsTable, CallTagsTable },
  props: {
    events: {
      required: true
    },
    tags: {
      required: true
    },
  },
  data: () => ({
    tab: null,
  }),
}
</script>
