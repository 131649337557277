<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      text
      outlined
      class="rounded filter-btn px-8"
      :height="height"
      :min-width="minWidth"
      :width="width"
      :ripple="false"
    >
      <v-icon :color="hover ? 'inputColorHover' : 'inputColor'">$filterIcon</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'FilterButton',
  inheritAttrs: false,
  props: {
    height: {
      type: Number | String,
      default: 48
    },
    minWidth: {
      type: Number | String,
      default: 54
    },
    width: {
      type: Number | String,
      default: 54
    }
  }
}
</script>

<style lang="scss">
.v-application {
  .v-btn {
    &.filter-btn {
      background: var(--v-headerInputBg-base);
      border-color: var(--v-inputBorder-base);
      
      &:hover {
        border-color: var(--v-inputBorderHover-base)!important;
      }
    }
  }
}
</style>