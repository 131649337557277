<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="700px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card
      class="cardBg py-16 py-sm-18 px-12 px-sm-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0"
      :class="{ 'pl-sm-20': !checkXMd }"
    >
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            adjust call payment
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col cols="12" :sm="checkXMd ? 7 : 12">
            <v-row class="pb-10" no-gutters>
              <v-col cols="12" :sm="checkXMd ? 7 : 5" align-self="center">
                <div
                  class="nameField--text pr-11 text-sm-right pb-4 pb-sm-0"
                >Revenue adjustment, $</div>
              </v-col>
              <v-col class="d-flex align-center" cols="12" sm="5">
                <InputNumberControl
                  v-model="formData.revenue"
                  :min="0"
                  :step="0.01"
                  width="75"
                  hide-details
                />
                <div class="font-normal pl-9" :class="getColorRevenue">
                  {{ getDifferenceRevenue }}
                </div>
              </v-col>
            </v-row>
            
            <v-row no-gutters>
              <v-col cols="12" :sm="checkXMd ? 7 : 5" align-self="center">
                <div
                  class="nameField--text pr-11 text-sm-right pb-4 pb-sm-0"
                >Payout adjustment, $</div>
              </v-col>
              <v-col class="d-flex align-center" cols="12" sm="5">
                <InputNumberControl
                  v-model="formData.payout"
                  :min="0"
                  :step="0.01"
                  width="75"
                  hide-details
                />
                <div class="font-normal pl-9" :class="getColorPayout">
                  {{ getDifferencePayout }}
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" :sm="checkXMd ? 5 : 12">
            <FormTextarea
              :class="{ 'pt-10': !checkXMd }"
              v-model="formData.comment"
              placeholder="Your comment"
              rows="4"
              auto-grow
            />
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        <ActionButton
          class="ml-5 ml-sm-11"
          :loading="loading"
          :disabled="disableActionBtn"
          @click="submitForm"
        >
          Adjust payout / revenue
        </ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InputNumberControl from '@/components/inputs/InputNumberControl'
import FormTextarea from '@/components/inputs/FormTextarea'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { sendAdjustingCallPayment } from '@/api/reporting-routes'

export default {
  name: 'DialogAdjustCallPayment',
  inheritAttrs: false,
  components: { InputNumberControl, FormTextarea, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    dataCall: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      revenue: 0,
      payout: 0,
      comment: null,
    },
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    getColorRevenue() {
      if (this.dataCall && !!(this.dataCall.revenue - this.formData.revenue)) {
        return this.dataCall.revenue > this.formData.revenue ? 'pink--text' : 'yellowLight--text'
      }
      return ''
    },
    getColorPayout() {
      if (this.dataCall && !!(this.dataCall.payout - this.formData.payout)) {
        return this.dataCall.payout > this.formData.payout ? 'pink--text' : 'yellowLight--text'
      }
      return ''
    },
    getDifferenceRevenue() {
      if (this.dataCall) {
        const difference = this.formData.revenue - this.dataCall.revenue
        return `${difference > 0 ? '+' : ''}${(difference).toFixed(2)}`
      }
      return ''
    },
    getDifferencePayout() {
      if (this.dataCall) {
        const difference = this.formData.payout - this.dataCall.payout
        return `${difference > 0 ? '+' : ''}${(difference).toFixed(2)}`
      }
      return ''
    },
    disableActionBtn() {
      return !(this.dataCall
        && (
          !!(this.dataCall.revenue - this.formData.revenue) ||
          !!(this.dataCall.payout - this.formData.payout)
        )
      )
    },
    checkXMd() {
      return this.$vuetify.breakpoint.width > 700
    },
  },
  methods: {
    parseProps() {
      Object.keys(this.formData).forEach(name => {
        this.formData[name] = this.dataCall[name]
      })
    },
    async submitForm() {
      this.loading = true
      
      const { success, payload, message } = await sendAdjustingCallPayment({
        callId: this.dataCall.uuid,
        formData: this.formData
      })
      
      if (success) {
        !!message && this.$notify({ type: 'success-bg', text: message })
        this.$emit('actionSuccess', payload)
        this.closeDialog()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.parseProps()
        } else {
          this.loading = false
        }
      },
    },
  },
}
</script>
