function parseFiltersData(payload) {
  return payload.map(({ filter, name }) => {
    return {
      filter,
      name,
      loading: false,
      items: [],
      current: null
    }
  })
}

function parseTreeUrlParams(tree) {
  return Object.keys(tree).reduce((arr,name, i) => {
    if (Array.isArray(tree[name])) {
      arr.push({
        name,
        id: ++i,
        items: tree[name].map(item => ({ name: item.name, id: item.url_param }))
      })
    } else if (typeof tree[name] === 'object' && Object.keys(tree[name]).length) {
      const tsItems = Object.keys(tree[name]).map((tsName, j) => ({
        name: tsName,
        id: (++i) + 'ts' + (++j),
        items: tree[name][tsName].map(item => ({ name: item.name, id: item.url_param }))
      }))
      arr.push(...tsItems)
    }
    
    return arr
  }, [])
}

function parseTheFilterData(payload) {
  return payload.map(item => {
    if (typeof item === 'object') {
      return {
        id: item.slug ? item.slug : item.id,
        name: item.number ? item.number : item.name ? item.name : ''
      }
    } else {
      return {
        id: item,
        name: item
      }
    }
  })
}

function parseAggGroups(payload) {
  return Object.keys(payload).map(id => ({id, name: payload[id]}))
}

function parseFilterParams(filterParams) {
  return Object.keys(filterParams).reduce((filters, name) => {
    if (filterParams[name]) {
      if (name === 'tags') {
        const tags = {}

        Object.keys(filterParams[name]).forEach(tagName => {
          Object.keys(filterParams[name][tagName]).forEach(itemName => {
            tags[`tags[${tagName}][${itemName}]`] = filterParams[name][tagName][itemName].id
          })
        })
        return Object.assign({}, filters, { ...tags })
      } else {
        return Object.assign({}, filters, { [name]: filterParams[name].id })
      }

    } else {
      return filters
    }
  }, {})
}

export { parseFiltersData, parseTreeUrlParams, parseTheFilterData, parseAggGroups, parseFilterParams }