<template>
  <v-menu
    ref="menu"
    v-model="menu"
    v-bind="menuProps"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    allow-overflow
    nudge-top="-5"
    :min-width="minWidth"
    :nudge-left="left"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateRangeText"
        v-bind="$attrs"
        v-on="on"
        class="font-normal header-input"
        background-color="headerInputBg"
        :class="{'rotate-icon picker-is-open': menu}"
        flat
        solo
        @click:append="menu = !menu"
        append-icon="mdi-chevron-down"
        item-color="selectItem"
        readonly
        hide-details
      />
    </template>
    <v-card :max-width="cardWidth" :min-width="cardWidth" color="rangePickerBg dateRangePicker" outlined>
      <v-card-text class="px-10 py-9">
        <v-row justify="space-between" no-gutters>
          <v-col
            v-if="checkSm || !custom"
            class="py-0"
            :sm="custom ? 4 : 12"
            :md="custom ? 2 : 12"
          >
            <v-list class="pa-0" color="rangePickerBg">
              <v-list-item
                class="px-0"
                v-for="(preset, index) in presets"
                v-model="isPresetActive[index]"
                :key="index"
                @click="selectPreset(index)"
                :ripple="false"
              >
                <v-list-item-content class="pt-0 pb-5">
                  {{ preset.label }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="px-0"
                @click="customRange"
                :ripple="false"
              >
                <v-list-item-content class="py-0">
                  Custom range
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-slide-x-transition>
            <v-col class="py-0 pt-2" cols="12" sm="8" md="10" v-if="rangePicker">
              <v-row>
                <v-col class="pa-0" cols="12" md="6">
                  <div class="flex pl-2 pb-6" v-if="!checkSm">
                    <v-icon color="drawerMenuIcon" @click="customRange">$backIcon</v-icon>
                    <span class="pl-6">Custom range</span>
                  </div>
                  <v-date-picker
                    class="mx-2 mx-sm-4"
                    v-model="dates"
                    color="violet"
                    no-title
                    :max="max"
                    range
                    :show-current="false"
                    @dblclick:date="applyRange"
                  />
                </v-col>
                <v-col class="pa-0" cols="12" sm="6" v-if="checkMd">
                  <v-date-picker
                    class="mx-4 mx-8"
                    v-model="dates"
                    color="violet"
                    no-title
                    :max="max"
                    range
                    :show-current="false"
                    @dblclick:date="applyRange"
                  />
                </v-col>
                <v-col class="pb-0 pr-4 pt-8 d-flex justify-end" cols="12">
                  <CancelButton
                    @click="menu = !menu"
                    height="46"
                    class="mx-8 px-2"
                  >
                    Cancel
                  </CancelButton>
                  <ActionButton
                    height="46"
                    @click="applyRange"
                  >
                    Apply
                  </ActionButton>
                </v-col>
              </v-row>
            </v-col>
          </v-slide-x-transition>
        </v-row>
      </v-card-text>

      <DialogSendCallLogPin
        v-model="sendPinDialog"
        @successPin="successPin"
        @closeDialog="closeDialog"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import DialogSendCallLogPin from '@/components/dialog/DialogSendCallLogPin'

import { format, subDays, startOfWeek, startOfMonth, subMonths, lastDayOfMonth, startOfYear } from 'date-fns'
import { defaultDate, defaultDateByTzName, getDateUTC, isoFormat} from '@/helper/app-helper'

import { checkCallLogDate } from '@/api/security-routes'

export default {
  name: 'DateRangePicker',
  inheritAttrs: false,
  components: { CancelButton, ActionButton, DialogSendCallLogPin },
  props: {
    value: {
      type: Array,
      default: () => [
        defaultDateByTzName(this.timezoneName),
        defaultDateByTzName(this.timezoneName)
      ]
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
    timezoneId: {
      type: Number | String,
      default: null
    },
    needToCheckDate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    timezoneName: null,
    sendPinDialog: false,
    menu: false,
    trigger: false,
    custom: false,
    rangePicker: false,
    dates: [defaultDate(), defaultDate()],
    focusSelect: false,
    currentPresetIndex: 0,
  }),
  computed: {
    ...mapState({
      timezones: state => state.timezones,
    }),
    max() {
      const t = this.trigger
      return defaultDateByTzName(this.timezoneName)
    },
    presets() {
      const t = this.trigger

      return [{
        label: 'Today',
        range: [
          defaultDateByTzName(this.timezoneName),
          defaultDateByTzName(this.timezoneName)
        ],
      }, {
        label: 'Yesterday',
        range: [
          defaultDateByTzName(this.timezoneName, subDays(new Date(), 1)),
          defaultDateByTzName(this.timezoneName, subDays(new Date(), 1))
        ]
      }, {
        label: 'This Week',
        range: [
          defaultDateByTzName(this.timezoneName, startOfWeek(new Date(), { weekStartsOn: 1 })),
          defaultDateByTzName(this.timezoneName)
        ]
      }, {
        label: 'Last 7 Days',
        range: [
          defaultDateByTzName(this.timezoneName, subDays(new Date(), 6)),
          defaultDateByTzName(this.timezoneName)
        ]
      }, {
        label: 'Last 30 Days',
        range: [
          defaultDateByTzName(this.timezoneName, subDays(new Date(), 29)),
          defaultDateByTzName(this.timezoneName)
        ]
      }, {
        label: 'This Month',
        range: [
          format(startOfMonth(new Date()), isoFormat),
          defaultDateByTzName(this.timezoneName)
        ]
      }, {
        label: 'Last Month',
        range: [
          format(startOfMonth(subMonths(new Date(), 1)), isoFormat),
          format(lastDayOfMonth(subMonths(new Date(), 1)), isoFormat)
        ]
      }, {
        label: 'This Year',
        range: [
          format(startOfYear(new Date()), isoFormat),
          defaultDateByTzName(this.timezoneName)
        ]
      }]
    },
    dateRangeText: {
      get() {
        return this.value.map(date => format(new Date(date), isoFormat)).join(' ~ ')
      },
      set() {}
    },
    isPresetActive() {
      let findFirst = false
      const currentPreset = this.presets.map(preset => {
        if (findFirst) {
          return false
        }

        const result = (
          preset.range[0] === format(new Date(this.value[0]), isoFormat) &&
          preset.range[1] === format(new Date(this.value[1]), isoFormat)
        )
        findFirst = result
        return findFirst
      })

      this.$nextTick(() => {
        this.custom = this.rangePicker = currentPreset.every(item => !item)
      })

      return currentPreset
    },
    mobileXS() {
      return this.$vuetify.breakpoint.xs
    },
    minWidth() {
      return this.custom ? 300 : 145
    },
    left() {
      return this.mobileXS && this.custom ? 25 : 0
    },
    cardWidth() {
      return this.custom ? this.checkMd ? 710 : this.checkSm ? 445 : 300 : 145
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  mounted() {
    this.dates = [
      defaultDateByTzName(this.timezoneName),
      defaultDateByTzName(this.timezoneName)
    ]
  },
  methods: {
    customRange() {
      if (this.custom) {
        this.rangePicker = false
        setTimeout(() => {
          this.custom = false
        }, 330)
      } else {
        this.rangePicker = true
        this.custom = true
      }
    },
    selectPreset(presetIndex) {
      this.currentPresetIndex = presetIndex
      this.dates = this.presets[presetIndex].range
      this.applyRange()
    },
    applyRange({ updateAfterChangedTimezone = false } = { updateAfterChangedTimezone: false }) {
      this.dates = this.dates.length === 1 ? [ this.dates[0], this.dates[0] ] : this.dates
      this.dates.sort((a, b) => getDateUTC(a) - getDateUTC(b))
      if (
        getDateUTC(this.dates[0]) !== getDateUTC(this.value[0])
        ||
        getDateUTC(this.dates[1]) !== getDateUTC(this.value[1])
        ||
        updateAfterChangedTimezone
      ) {
        this.checkCallLogDate()
      }
      this.menu = false
    },
    async checkCallLogDate() {
      if (this.needToCheckDate) {
        const { success, payload, message } = await checkCallLogDate({ date: this.dates[0] })

        if (success) {
          if (payload) {
            this.sendPinDialog = true
          } else {
            this.$emit('input', this.dates)
          }
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
      } else {
        this.$emit('input', this.dates)
      }
    },
    successPin({ payload, pin}) {
      if (payload) {
        this.$emit('savePin', pin)
        this.$emit('input', this.dates)
        this.sendPinDialog = false
      } else {
        this.$notify({ type: 'error-bg', duration: 15000, text: 'Invalid code!' })
      }
    },
    closeDialog() {
      this.$emit('savePin', null)
      this.dates = this.value
    },
    setTimezoneName(id) {
      this.trigger = !this.trigger

      const currentTZ = this.timezones.find(t => t.id === id)
      
      this.timezoneName = currentTZ ? currentTZ.php : null
    },
  },
  watch: {
    timezoneId: {
      immediate: true,
      handler(id, oldId) {
        if (!id || id === -1) {
          return
        }

        let presetIsSelected = false

        if (!!oldId) {
          const selectedPresetIndex = this.isPresetActive.findIndex(p => !!p)

          if (selectedPresetIndex + 1) {
            presetIsSelected = true
            this.currentPresetIndex = selectedPresetIndex
          } else {
            presetIsSelected = false
          }

        }

        this.setTimezoneName(id)

        if (!!oldId) {
          if (presetIsSelected) {
            this.dates = this.presets[this.currentPresetIndex].range
          }
          this.applyRange({ updateAfterChangedTimezone: true })
        }
      }
    },
  },
}
</script>
