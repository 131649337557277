<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex justify-space-between">
      <div class="d-flex flex-grow-1 flex-column mr-n5 mr-md-0">
        <div class="d-flex flex-wrap">
          <portal to="pageTitle" :disabled="checkMd">
            <div class="font-page-header pt-md-7 pr-5 pr-sm-15">reporting</div>
          </portal>
          
          <div class="date-container pr-5 pr-xl-15 pb-5 pb-xl-10">
            <DateRangePicker
              v-if="range.length"
              v-model="range"
              :timezoneId="timezoneId"
              :needToCheckDate="callLogPinEnabled"
              height="48"
              @savePin="savePin"
            />
          </div>
          
          <SelectSearchHeader
            class="timezone-container pr-5 pr-xl-15 gray-border pb-5 pb-xl-10"
            v-model="timezoneId"
            :items="timezones"
            item-value="id"
            item-text="display"
            height="48"
            :menu-props="{ nudgeTop: -5, contentClass: 'select-search-header' }"
            @change="changeTimezone"
            @blur="checkTimezoneIsEmpty"
          />
          
          <SelectSearchHeader
            class="time-group-container mr-5 mr-xl-15 gray-border pb-5 pb-xl-10"
            v-model="timeGroupId"
            :items="outputBy"
            item-value="id"
            item-text="name"
            height="48"
            notEmpty
            :menu-props="{ nudgeTop: -5, contentClass: 'select-search-header' }"
            @blur="checkTimeGroupIdIsEmpty"
          />
          
          <RefreshButton
            class="mr-5 mr-xl-15 mb-5 mb-xl-10"
            :disabled="loadPage"
            @click="refresh"
          />
          
          <AutoRefreshButton class="mr-5 mr-xl-15 mb-5 mb-xl-10" v-model="autoRefreshActive" />
        </div>
        
        <div class="d-flex flex-wrap">
          <div
            class="filterValue pb-5 pb-xl-10"
            v-if="availableFilters.length"
            v-for="(filter, index) in availableFilters"
            :key="index"
          >
            <v-btn
              class="pl-11 pr-11 filter-button filterBtnBg mr-5 mr-xl-11"
              :class="{ 'filter-remove-disabled': aggDataIsLoading }"
              height="48"
              :ripple="false"
              outlined
              @click="deleteFilterValue(filter.filterName, filter)"
            >
              <v-icon left>mdi-close</v-icon>
              
              <span class="font-normal minWidth--50 text-left">{{ filter.name }}</span>
              
              <div class="btn-label capitalize">{{ filter.filterName }}</div>
            </v-btn>
          </div>
        </div>
        
        <v-spacer/>
        
        <portal-target
          class="ml-auto pr-5"
          name="reportFilter"
        ></portal-target>
      
      </div>
      
      <portal to="reportFilter" :disabled="checkMd" class="ml-5 d-flex flex-nowrap align-start">
        <ResetButton
          v-if="availableFilters.length"
          class="mr-5 mr-xl-11"
          @click="resetFilter"
        >Reset</ResetButton>
        
        <FilterButton
          :minWidth="52"
          :width="52"
          :height="48"
          @click="openFilters"
        >
          <v-icon>$filterIcon</v-icon>
        </FilterButton>
      </portal>
    </div>
    
    <ChartReporting :chartData="chartData" :isLoading="chartIsLoading" :timeGroupId="!!timeGroupId ? timeGroupId : ''" />

    <AggregatedStatistics
      :aggregatedGroups="aggregatedGroups"
      :currentGroup.sync="currentGroup"
      :items="aggregatedData"
      :loading="aggDataIsLoading"
      :csvIsLoading="csvAggIsLoading"
      :filters.sync="filterParams"
      @downloadAggCSV="checkPinForDownloadCSV('downloadAggCSV')"
      @changeGroup="changeGroup"
    />
    
    <CallLogStatistics
      :items.sync="callLogData"
      :dataPagination.sync="dataPagination"
      :loading="callLogDataIsLoading"
      :csvIsLoading="csvCallLogIsLoading"
      :filters.sync="filterParams"
      :timezoneId="timezoneId"
      @pageParamsChanged="loadCallLog"
      @downloadCallLogCSV="checkPinForDownloadCSV('downloadCallLogCSV')"
      @sortBy="sortBy"
      @changeSearch="changeSearch"
    />
    
    <ReportingFilters
      v-model="visibleFilters"
      :filters.sync="filterParams"
      :params="params"
    />

    <DialogSendCallLogPin
      v-model="sendPinDialog"
      @successPin="successPin"
      @closeDialog="closeDialog"
    />
  
  </v-container>
</template>

<script>
import DateRangePicker from '@/components/pickers/DateRangePicker'
import ChartReporting from '@/components/reporting/ChartReporting'
import AggregatedStatistics from '@/components/reporting/AggregatedStatistics'
import ReportingFilters from '@/components/reporting/ReportingFilters'
import CallLogStatistics from '@/components/reporting/CallLogStatistics'
import AutoRefreshButton from '@/components/buttons/AutoRefreshButton'
import ResetButton from '@/components/buttons/ResetButton'
import FilterButton from '@/components/buttons/FilterButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SelectSearchHeader from '@/components/select/SelectSearchHeader'
import DialogSendCallLogPin from '@/components/dialog/DialogSendCallLogPin'

import {
  defaultDateByTzName,
  downloadCSVFile,
  getOnPage,
  getDateUTC,
  setTimezoneInLocalStorage,
  getCurrentTimezone,
  copyObject,
  parsePaginationData
} from '@/helper/app-helper'

import { mapState, mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getCallLogData, getChartData, getAggGroupNames, getAggGroupData, getDownloadCallLogCSV, getDownloadAggCSV } from '@/api/reporting-routes'
import { parseAggGroups, parseFilterParams } from '@/helper/reporting-helper'

export default {
  name: 'Reporting',
  components: { DateRangePicker, ChartReporting, AggregatedStatistics, ReportingFilters, CallLogStatistics, AutoRefreshButton, ResetButton, FilterButton, RefreshButton, SelectSearchHeader, DialogSendCallLogPin },
  data: () => ({
    dontCheckTimeGroup: false,
    sendPinDialog: false,
    chartIsLoading: false,
    aggDataIsLoading: false,
    callLogDataIsLoading: false,
    csvAggIsLoading: false,
    csvCallLogIsLoading: false,
    loadPage: false,
    isLoading: false,
    watchTimeGroup: true,
    visibleFilters: false,
    autoRefreshActive: false,
    isRefresh: false,
    autoRefreshInterval: null,
    timezoneName: null,
    filters: [],
    range: [],
    timeGroupId: 'byHour',
    oldTimeGroupId: null,
    timezoneId: null,
    pin: null,
    outputBy: [
      { name: 'By Hour', id: 'byHour' },
      { name: 'By Day', id: 'byDay' },
      { name: 'By Month', id: 'byMonth' },
    ],
    filterParams: {},
    groupParams: {},
    chartData: [],
    filtersData: [],
    aggregatedGroups: [],
    aggregatedData: [],
    callLogData: [],
    currentGroup: 0,
    dataPagination: {
      page: 1,
      lastPage: 1
    },
    sortValue: null,
    search: null,
    firstLoad: true,
    CSVAction: '',
  }),
  computed: {
    ...mapState({
      timezones: state => state.timezones,
      accountTimezoneId: state => state.auth.timezoneId,
      accountId: state => state.auth.accountId,
      role: state => state.auth.role,
    }),
    ...mapGetters({
      callLogPinEnabled: authTypes.getters.CALL_LOG_PIN_ENABLED,
    }),
    availableFilters() {
      const array = []
      Object.keys(this.filterParams).forEach(name => {
        if (!!this.filterParams[name]) {
          if (name === 'tags') {
            Object.keys(this.filterParams[name]).forEach(tagName => {
              Object.keys(this.filterParams[name][tagName]).forEach(itemName => {
                array.push(this.filterParams[name][tagName][itemName])
              })
            })
          } else {
            array.push(this.filterParams[name])
          }
        }
      })
      return array
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    params() {
      return {
        date: this.range[0],
        dateTo: this.range[1],
        timezone: this.timezoneId,
        pin: this.pin,
      }
    },
  },
  mounted() {
    this.setTimezoneName()

    this.range = [
      defaultDateByTzName(this.timezoneName),
      defaultDateByTzName(this.timezoneName)
    ]
  },
  methods: {
    successPin({ payload, pin}) {
      if (payload) {
        this.savePin(pin)
        this.sendPinDialog = false
        this[this.CSVAction]()
      } else {
        this.$notify({ type: 'error-bg', duration: 15000, text: 'Invalid code!' })
      }
    },
    closeDialog() {
      this.savePin(null)
    },
    setTimezoneName() {
      const currentTZ = this.timezones.find(t => t.id === this.timezoneId)

      this.timezoneName = currentTZ ? currentTZ.php : null
    },
    savePin(pin) {
      this.pin = pin
    },
    refresh() {
      this.dontCheckTimeGroup = true
      const id = this.timezoneId
      this.timezoneId = -1
      this.$nextTick(() => {
        this.timezoneId = id
      })
    },
    loadReportingData() {
      this.loadChartData()
      
      setTimeout(() => {
        this.loadAggregateData()
      }, 200)

      if (!this.firstLoad) {
        setTimeout(() => {
          this.loadCallLog()
        }, 300)
      }

    },
    changeDateRange() {
      this.dataPagination.page = 1
      if (!this.dontCheckTimeGroup) {
        this.checkDateRange()
      }
      this.loadReportingData()
    },
    checkTimezoneIsEmpty() {
      if (!this.timezoneId) {
        this.timezoneId = getCurrentTimezone()
      }
    },
    checkTimeGroupIdIsEmpty() {
      if (!this.timeGroupId && !this.dontCheckTimeGroup) {
        this.checkDateRange()
      }
    },
    changeTimezone() {
      if (!this.timezoneId) {
        return
      }
      this.dontCheckTimeGroup = true

      this.dataPagination.page = 1

      setTimezoneInLocalStorage(this.timezoneId)
    },
    async loadChartData() {
      this.chartIsLoading = true
      if (!this.timeGroupId && !this.dontCheckTimeGroup) {
        this.checkDateRange()
      }
      
      const { success, payload, message } = await getChartData({
        ...this.params,
        ...parseFilterParams(this.filterParams),
        timeGroup: this.timeGroupId,
      })

      if (success) {
        this.chartData = Array.isArray(payload) ? payload : []
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.dontCheckTimeGroup = false
      this.chartIsLoading = false
      this.watchTimeGroup = true
    },
    checkDateRange() {
      if (this.oldTimeGroupId) {
        this.timeGroupId = this.oldTimeGroupId
        this.oldTimeGroupId = null
        return
      }

      const range = getDateUTC(new Date(this.range[1])) - getDateUTC(new Date(this.range[0]))
      this.watchTimeGroup = false
      const months = range / (31 * 24 * 3600 * 1000)
      if (months > 3) {
        this.timeGroupId = 'byMonth'
      } else if (months === 0) {
        this.timeGroupId = 'byHour'
      } else {
        this.timeGroupId = 'byDay'
      }
    },
    changeGroup(groupParams) {
      this.groupParams = groupParams
      this.loadAggregateData()
    },
    async loadAggregateData() {
      if (this.aggDataIsLoading) {
        return
      }

      this.aggDataIsLoading = true
      
      if (!this.aggregatedGroups?.length) {
        await this.loadAggregateGroups()
        this.currentGroup = 0
        this.groupParams = {
          groupBy: this.aggregatedGroups[0].id
        }
      }
      
      if (this.aggregatedGroups?.length) {
        const { success, payload, message } = await getAggGroupData({
          ...this.params,
          ...parseFilterParams(this.filterParams),
          ...this.groupParams
        })
        
        if (success) {
          this.aggregatedData = Array.isArray(payload) ? payload : []
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }
      }

      if (this.firstLoad) {
        this.loadCallLog()
      }
      
      this.aggDataIsLoading = false
    },
    changeSearch(search) {
      this.search = search
      this.dataPagination.page = 1
      this.loadCallLog()
    },
    async loadAggregateGroups() {
      const { success, payload, message } = await getAggGroupNames()
      
      if (success) {
        this.aggregatedGroups = parseAggGroups(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadCallLog() {
      this.callLogDataIsLoading = true
      
      const { success, payload, message } = await getCallLogData({
        onPage: getOnPage(),
        page: this.dataPagination.page,
        search: this.search,
        ...this.params,
        ...parseFilterParams(this.filterParams),
        ...this.sortValue
      })
      
      if (success) {
        this.callLogData = payload.data
        this.dataPagination = parsePaginationData(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.callLogDataIsLoading = false
      this.firstLoad = false
    },
    deleteFilterValue(filterName, filter) {
      if (!this.aggDataIsLoading) {
        if (filter.filterName.split(' ')[0] === 'Tag') {
          const currentTagName = filter.filterName.split(' ')[2]
          Object.keys(this.filterParams.tags).forEach(tagName => {
            if (tagName === currentTagName) {
              Object.keys(this.filterParams.tags[tagName]).forEach((itemName, i , arr) => {
                if (
                  this.filterParams.tags[tagName][itemName].filterName === filterName &&
                  this.filterParams.tags[tagName][itemName].id === filter.id
                ) {
                  if (arr.length === 1) {
                    delete this.filterParams.tags[tagName]
                  } else {
                    delete this.filterParams.tags[tagName][itemName]
                  }

                  this.filterParams = copyObject(this.filterParams)
                }
              })
            }
          })
        } else {
          const deleteFilterName = Object.keys(this.filterParams).find(name => this.filterParams[name]?.filterName === filterName)
          this.filterParams[deleteFilterName] = null
        }
      }
    },
    resetFilter() {
      this.filterParams = {}
    },
    openFilters() {
      this.visibleFilters = true
    },
    checkPinForDownloadCSV(action) {
      if (this.callLogPinEnabled) {
        this.CSVAction = action
        this.sendPinDialog = true
      } else {
        this[action]()
      }
    },
    async downloadAggCSV() {
      this.csvAggIsLoading = true
      
      const res = await getDownloadAggCSV({
        ...this.params,
        ...parseFilterParams(this.filterParams),
        ...this.groupParams
      })

      if (res?.data && res.data.success === false) {
        !!res.data.message && this.$notify({ type: 'error-bg', text: res.data.message })
      } else if (res?.success === false ) {
        !!res.message && this.$notify({ type: 'error-bg', text: res.message })
      } else {
        downloadCSVFile(res)
      }
      
      this.csvAggIsLoading = false
    },
    async downloadCallLogCSV() {
      this.csvCallLogIsLoading = true

      const res = await getDownloadCallLogCSV({
        ...this.params,
        ...parseFilterParams(this.filterParams)
      })

      if (res?.data && res.data.success === false) {
        !!res.data.message && this.$notify({ type: 'error-bg', text: res.data.message })
      } else if (res?.success === false ) {
        !!res.message && this.$notify({ type: 'error-bg', text: res.message })
      } else {
        downloadCSVFile(res)
      }
      
      this.csvCallLogIsLoading = false
    },
    sortBy(value) {
      if (this.sortValue !== value) {
        this.sortValue = value
        this.loadCallLog()
      }
    },
    checkTimezone(userTimezoneId) {
      const changedTimezoneId = getCurrentTimezone()

      if (this.checkValidTimezoneId(changedTimezoneId)) {
        this.timezoneId = changedTimezoneId
      } else {
        this.timezoneId = userTimezoneId
        setTimezoneInLocalStorage(userTimezoneId)
      }
    },
    getTimezoneNameById(id) {
      const currentTZ = this.timezones.find(t => t.id === id)

      if (!currentTZ) {
        return ''
      }

      return currentTZ.php
    },
    checkValidTimezoneId(id) {
      const checkedTimezone = this.timezones.find(t => t.id === id)
      
      return !!checkedTimezone
    }
  },
  watch: {
    filterParams: {
      deep: true,
      handler() {
        this.dataPagination.page = 1
        this.refresh()
      },
    },
    accountTimezoneId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.checkTimezone(id)
        }
      },
    },
    accountId: {
      handler(accountId) {
        if (!!accountId) {
          this.checkTimezone(this.accountTimezoneId)
          this.aggregatedGroups = []
          this.resetFilter()
        } else {
          clearInterval(this.autoRefreshInterval)
        }
      }
    },
    autoRefreshActive: {
      handler(active) {
        if (active) {
          this.autoRefreshInterval = setInterval(() => {
            this.refresh()
          }, 30000)
        } else {
          clearInterval(this.autoRefreshInterval)
        }
      },
    },
    timeGroupId: {
      handler(value, oldValue) {
        if (!value) {
          if (!!oldValue) {
            this.oldTimeGroupId = oldValue
          }

          return
        }

        if (this.watchTimeGroup) {
          this.loadChartData()
        }
        this.watchTimeGroup = true
      }
    },
    range: {
      handler() {
        this.changeDateRange()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.autoRefreshInterval)
  },
}
</script>
