<template>
  <div class="pa-0 cardChildBg">
    <v-card
      v-if="!events.length"
      class="py-8 cardChildBg rounded-0 pl-34 ml-2 tableBodyText--text"
      flat
    >
      No data available
    </v-card>
    <v-card
      v-else
      v-for="(event, index) of events"
      v-bind="$attrs"
      :key="index"
      class="py-8 cardChildBg mx-auto eventCard rounded-0"
      min-width="300"
      flat
    >
      <v-simple-table class="cardChildBg">
        <template #default>
          <tbody>
          <tr
            v-for="(nameValue, index) of Object.keys(event.values)"
            :key="nameValue + event['event_name']"
          >
            <td class="eventStartName"></td>
            <td
              class="px-sm-8 font-small-table-header eventName eventTitle--text"
            >{{ index ? '' : event['event_name'] }}
            </td>
            <td class="px-sm-8 eventName eventValue--text">{{ nameValue }}</td>
            <td
              class="px-0 px-md-8 py-2 eventValue--text"
              v-if="event.values[nameValue]"
            >
              <p
                class="ma-0"
                :class="{ ellipsis: breakpoint.width < 650 }"
                v-html="eventFormat(event.values[nameValue])"
              ></p>
            </td>
            <td class="eventValue--text font-small" v-else>-//-</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CallEventsTable',
  inheritAttrs: false,
  props: {
    events: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    headers: [
      { text: 'Event Name', value: 'event_name', class: 'py-6', sortable: false },
      { text: 'Values', value: 'valuesName', class: 'py-6', sortable: false },
    ],
  }),
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint
    },
  },
  methods: {
    eventFormat(event) {
      return typeof event !== 'number' ? event.split('\n').join('<br>') : event
    },
  },
}
</script>
<style lang="scss">
.cardChildBg {
  .ellipsis {
    max-width: 230px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  td.eventStartName {
    width: 65px;
  }
  
  td.eventName {
    width: 150px;
  }
  
  .eventCard {
    border-bottom: 1px solid var(--v-cardBorder-base) !important;
    
    &:last-child {
      border-bottom: none !important;
    }
    
    .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row) {
      border-bottom: none;
    }
    
    .v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child {
      border-bottom: none;
    }
    
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
      height: 25px;
    }
  }
}
</style>
