<template>
  <div class="pa-0 cardChildBg">
    <v-card
      v-if="items.length"
      class="cardChildBg mx-auto tag-card rounded-0"
      min-width="300"
      flat
    >
      <v-data-table
        v-bind="$attrs"
        class="elevation-0 cardChildBg tag-table"
        :headers="headers"
        :items="items"
        :mobile-breakpoint="1140"
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
        :header-props="{ sortIcon: '$sortDownIcon' }"
      />
    </v-card>
    <v-card
      v-else
      class="py-8 cardChildBg rounded-0 pl-34 ml-2 tableBodyText--text"
      flat
    >
      No data available
    </v-card>
  </div>
</template>
<script>
export default {
  name: 'CallTagsTable',
  inheritAttrs: false,
  props: {
    tags: {
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: '', value: '', width: 65, sortable: false },
      { text: 'Type', value: 'type', width: 160 },
      { text: 'Name', value: 'name', width: 225 },
      { text: 'Value', value: 'value' }
    ],
    items: [],
    parseItems: null
  }),
  mounted() {
    if(!!this.tags) {
      this.loadTags()
    }
  },
  methods: {
    loadTags() {
      this.parseItems = Object.entries(this.tags)
      this.parseItems.forEach(type => {
        type[1].forEach((item) => {
          this.items.push({
            'type': type[0],
            'name': item['name'],
            'value': item['value']
          })
        })
      })
    }
  }
}
</script>
