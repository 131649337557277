<template>
  <v-slide-y-transition>
    <div
      v-if="show"
      class="expandPanelBg"
    >
      <v-form ref="form" v-model="validateForm">
        <v-row no-gutters :class="checkSmAndDown ? 'pa-4' : 'pa-15'">
          <v-col class="pa-4 pa-sm-5" cols="12" sm="6" md="4" lg="3">
            <div class="font-normal nameField--text mb-5">Tag group</div>
      
            <AutocompleteField
              v-model="currentTree"
              class="visibleDetails"
              :items="treeUrlParams"
              item-text="name"
              item-value="id"
              :menu-props="{ nudgeTop: -5 }"
              :rules="rules"
              return-object
              checkBg
            />
          </v-col>
    
          <v-col class="pa-4 pa-sm-5" cols="12" sm="6" md="4" lg="3">
            <div class="font-normal nameField--text mb-5">Tag name</div>
      
            <AutocompleteField
              class="visibleDetails"
              v-model="currentItem"
              :items="currentItems"
              item-text="name"
              item-value="id"
              :menu-props="{ nudgeTop: -5 }"
              :rules="rules"
              return-object
              checkBg
            />
          </v-col>
    
          <v-col class="pa-4 pa-sm-5" cols="12" sm="6" md="4" lg="3">
            <div class="font-normal nameField--text mb-5">Value</div>
  
            <AutocompleteField
              class="visibleDetails"
              v-model="currentValue"
              :items="values"
              item-text="name"
              item-value="id"
              :menu-props="{ nudgeTop: -5 }"
              :rules="rules"
              :loading="loadingValues"
              return-object
              checkBg
            />
          </v-col>
    
          <v-col class="pa-4 pa-sm-5 d-flex justify-end" cols="12" sm="6" md="4" lg="3" align-self="center">
            <CancelButton class="mr-8 mb-2" height="46" @click="show = false">
              Close
            </CancelButton>
            
            <ActionButton height="46" @click="setFilterByTags">
              Add and more
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-slide-y-transition>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import FormField from '@/components/inputs/FormField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { getTagValues } from '@/api/reporting-routes'

import { copyObject } from '@/helper/app-helper'

export default {
  name: 'FilterByTags',
  inheritAttrs: false,
  components: { AutocompleteField, FormField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    treeUrlParams: {
      type: Array,
      default: () => []
    },
    filtersByTags: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    validateForm: true,
    loadingValues: false,
    currentTree: null,
    currentItem: null,
    currentValue: null,
    values: [],
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    checkSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    currentItems() {
      return !!this.currentTree ? this.currentTree.items : []
    }
  },
  mounted() {
  
  },
  methods: {
    parseProps() {
    
    },
    async setFilterByTags() {
      await this.validationForm()
      
      if (this.$refs.form.validate()) {
        const filters = copyObject(this.filtersByTags)
        
        const tagFilter = {
          [this.currentItem.id]: {
            filterName: `Tag - ${this.currentTree.name}`,
            id: this.currentValue,
            name: `${this.currentItem.name}: ${this.currentValue}`,
          }
        }
        if (filters.hasOwnProperty(this.currentTree.name)) {
          filters[this.currentTree.name] = Object.assign(filters[this.currentTree.name], tagFilter)
        } else {
          filters[this.currentTree.name] = tagFilter
        }
        
        this.$emit('newFiltersByTags', filters)
        
        this.clearFilter()
      }
    },
    async loadTagValues() {
      if (!this.currentTree || !this.currentItem) {
        return
      }
      
      this.loadingValues = true
      
      const { success, payload, message } = await getTagValues({
        'TagGroup': this.currentTree.name,
        'TagKey': this.currentItem.id,
        ...this.params
      })
      
      if (success) {
        this.values = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingValues = false
    },
    clearFilter() {
      this.currentTree = null
      this.currentItem = null
      this.currentValue = null
      this.values = []
      this.rules = []
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
  },
  watch: {
    show: {
      handler(value) {
        if (value) {
          this.parseProps()
        } else {
          this.clearFilter()
        }
      },
    },
    currentTree: {
      handler() {
        this.rules = []
        this.currentItem = null
        this.currentValue = null
        this.loadTagValues()
      }
    },
    currentItem: {
      handler() {
        this.rules = []
        this.currentValue = null
        this.loadTagValues()
      }
    },
  },
}
</script>
