<template>
  <v-menu
    v-model="tokenMenu"
    :close-on-content-click="false"
    max-height="400"
    min-width="320"
  >
    <template #activator="{ on }">
      <v-hover #default="{ hover }">
        <v-btn
          class="px-10 font-medium text-uppercase appendArrowIcon"
          v-on="on"
          width="100%"
          height="100%"
          tile
          depressed
          text
        >
          <slot name="title" :hover="hover"></slot>
          
          <slot name="icon" :hover="hover"></slot>
        </v-btn>
      </v-hover>
    </template>
    
    <v-list class="tagMenuBg scroll-list" dense>
      <v-list-item v-if="!Object.keys(urlParams).length">
        <v-list-item-content>
          <v-list-item-title>No parameters</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group
        v-else
        v-for="name of Object.keys(urlParams)"
        :key="name"
        :ripple="false"
      >
        <template #activator>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="font-normal tagMenuColor--text">
                {{ name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <div v-if="isItemArray(urlParams[name])">
          <v-list-item
            v-for="(param, index) of urlParams[name]"
            :key="name + index"
            @click="changeParam(param, name)"
          >
            <v-list-item-content class="pl-18">
              <v-list-item-title class="font-normal tagMenuColor--text">
                {{ param.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-group
          v-else
          v-for="(subName, subIndex) of getParamsName(urlParams[name])"
          :key="subName + subIndex"
          :ripple="false"
          prepend-icon="mdi-chevron-down"
          no-action
          sub-group
        >
          <template #activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-normal tagMenuColor--text">
                  {{ subName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            class="pl-0"
            v-for="(subParam, subParamIndex) of urlParams[name][subName]"
            :key="subParam + subParamIndex"
            @click="changeParam(subParam, subName)"
          >
            <v-list-item-content class="pl-29">
              <v-list-item-title class="font-normal tagMenuColor--text">
                {{ subParam.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>


        </v-list-group>
      </v-list-group>
    </v-list>
  </v-menu>
</template>

<script>
import { getAllTreeUrlParams } from '@/api/integration-routes'

export default {
  name: 'MenuAggregatedByTags',
  inheritAttrs: false,
  data: () => ({
    tokenMenu: false,
    urlParams: {}
  }),
  computed: {},
  mounted() {
    this.loadAllUrlParams()
  },
  methods: {
    isItemArray(item) {
      return Array.isArray(item)
    },
    getParamsName(param) {
      return Object.keys(param)
    },
    async loadAllUrlParams() {
      const { success, payload, message } = await getAllTreeUrlParams()

      if (success) {
        this.urlParams = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    changeParam(param, groupName) {
      const tagFilter = {
        [groupName]: {
          [param.url_param]: {
            filterName: `Tag - ${groupName}`,
            id: null,
            name: `${param.name}: `
          }
        }
      }

      this.$emit('changeTag', {
        tagParams: {
          'AggTagGroup': groupName,
          'AggTagName': param.name
        },
        tagFilter
      })
      this.tokenMenu = false
    },
  },
}
</script>
