<template>
  <div id="call-log" class="call-log-container">
    <div class="d-flex flex-column flex-sm-row align-sm-center justify-space-between pt-3 pt-sm-5">
      <div class="font-page-header text-no-wrap py-5 py-sm-0">call log</div>
      
      <div class="d-flex flex-wrap align-center justify-end pt-5">
        <SearchField
          v-model="search"
          class="mb-5"
          @changedSearch="changeSearch"
        />

        <ActionOutlinedButton
          class="ml-5 ml-sm-8 mb-5"
          v-if="items.length && checkAbilityToDownload"
          :loading="csvIsLoading"
          @click="exportCSV"
        >Export CSV</ActionOutlinedButton>
  
        <SelectOnPage
          class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-5"
          :readonly="loading"
          @changeOnPage="refresh"
        />
      </div>
    </div>
    
    <v-data-table
      v-bind="$attrs"
      class="elevation-0 borderNone tableBg rounded-20 borderTable"
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :loader-height="2"
      :options="options"
      :expanded.sync="expanded"
      :custom-sort="() => items"
      show-expand
      :mobile-breakpoint="1140"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      :header-props="{ sortIcon: '$sortDownIcon' }"
      @update:options="changeOptions"
    >
      <template #no-data>
        <div class="d-flex">
          <span class="font-normal text-left">No data available</span>
        </div>
      </template>
  
      <template #item.callDate="{ item }">
        <span class="text-no-wrap">{{ getFormatDate(item['created_at']) }}</span>
      </template>
  
      <template #item.campaign_name="{ item }">
        <v-btn
          class="px-0 mr-3"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'campaign', filterName: 'Campaign', name: item['campaign_name'], id: item['campaign_name'] })"
        >
          <span class="text-no-wrap">{{ item['campaign_name'] }}</span>
        </v-btn>
  
        <v-icon class="copy-name-icon" size="14" @click.stop="copyName(item['campaign_name'])">$copyIcon</v-icon>
      </template>
  
      <template #item.publisher_name="{ item }">
        <v-btn
          v-if="item['publisher_name']"
          class="px-0 mr-3"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'publisher', filterName: 'Vendor', name: item['publisher_name'], id: item['publisher_name'] })"
        >
          <span class="text-no-wrap">{{ item['publisher_name'] }}</span>
        </v-btn>
  
        <v-icon v-if="item['publisher_name']" class="copy-name-icon" size="14" @click.stop="copyName(item['publisher_name'])">$copyIcon</v-icon>

        <span v-else>-//-</span>
      </template>
  
      <template #item.caller_number="{ item }">
        <v-btn
          class="px-0 mr-3"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'caller', filterName: 'Caller ID', name: item['caller_number'], id: item['caller_number'] })"
        >
          <span :class="{ 'pink--text': item['caller_in_bl'] }">
            +{{ item['caller_number'] }}
          </span>
        </v-btn>
  
        <v-icon class="copy-name-icon" size="14" @click.stop="copyName(item['caller_number'])">$copyIcon</v-icon>
      </template>
  
      <template #item.dialed="{ item }">
        <v-btn
          class="px-0 mr-3"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'number', filterName: 'Number', name: item.number.number, id: item.number.id })"
        >
          +{{ item.number.number }}
        </v-btn>
  
        <v-icon class="copy-name-icon" size="14" @click.stop="copyName(item.number.number)">$copyIcon</v-icon>
      </template>
  
      <template #item.dupe="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'dupe', filterName: 'Dupe', name: item.dupe ? 'Yes' : 'No', id: item.dupe })"
        >
          {{ item.dupe ? 'Yes' : 'No' }}
        </v-btn>
      </template>
  
      <template #item.target_name="{ item }">
          <v-btn
            v-if="item['target_name']"
            class="px-0 mr-3"
            text
            plain
            min-width="20"
            @click="callLogSetFilter({ filter: 'target', filterName: 'Destination', name: item['target_name'], id: item['target_name'] })"
          >
            <span class="text-no-wrap">{{ item['target_name'] }}</span>
          </v-btn>
  
          <v-icon
            v-if="item['target_name']"
            class="copy-name-icon"
            size="14"
            @click.stop="copyName(item['target_name'])"
          >$copyIcon</v-icon>
      </template>
  
      <template #item.revenue="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'revenue', filterName: 'Revenue', name: currency(item.revenue), id: item.revenue })"
        >
          {{ currency(item.revenue) }}
        </v-btn>
      </template>
  
      <template #item.payout="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="callLogSetFilter({ filter: 'payout', filterName: 'Payout', name: currency(item.payout), id: item.payout })"
        >
          {{ currency(item.payout) }}
        </v-btn>
      </template>
  
      <template #item.ttc="{ item }">
        {{ getTtc(item) }}
      </template>

        <template #item.duration="{ item }">
        <span v-if="!loading">{{ getDuration(item) }}</span>
      </template>
  
      <template #item.hangup="{ item }">
        <TooltipAction bottom :message="getHangupMessage(item['hangup_source'])">
          <template #activator>
            <v-icon color="iconOnTable">{{ getHangupIcon(item['hangup_source']) }}</v-icon>
          </template>
        </TooltipAction>
      </template>
  
      <template #item.status_id="{ item }">
        <div v-if="!!item.live">
          <div v-if="checkHangingUpCall(item.uuid)">Live - hanging up</div>

          <div class="d-flex justify-center items-center" v-else>
            <span class="pr-6 mt-1">Live</span>
            <TooltipAction bottom message="Stop call" v-if="checkStopCall">
              <template #activator>
                <v-icon class="mr-6" @click="openStopCall(item)">$stopCallIcon</v-icon>
              </template>
            </TooltipAction>
          </div>
        </div>

        <div v-else>{{ getStatus(item) }}</div>
      </template>

      <template #item.record="{ item }">
        <div v-if="item.duration">
          <v-icon
            v-if="item.id === recordId"
            @click="closePlayer"
            color="error"
          >
            $stopRedIcon
          </v-icon>
          <v-icon
            v-else-if="!!item.record"
            @click="openPlayer(item)"
          >
            $playIcon
          </v-icon>
        </div>
      </template>
      
      <template #item.actions="{ item }">
        <TooltipAction bottom message="Copy Call UUID">
          <template #activator>
            <v-icon class="mr-6" size="16" @click="copyName(item.uuid)">$copyIcon</v-icon>
          </template>
        </TooltipAction>

        <TooltipAction bottom message="Block number" v-if="!item['caller_in_bl'] && blockNumberPermission">
          <template #activator>
            <v-icon class="mr-6" @click="openBlockNumber(item)">$blockIcon</v-icon>
          </template>
        </TooltipAction>
        
        <TooltipAction bottom message="Adjust call payments" v-if="isManager">
          <template #activator>
            <v-icon @click="openAdjustCallPayment(item)">$dollarIcon</v-icon>
          </template>
        </TooltipAction>
      </template>
  
      <template #item.data-table-expand="{ expand, isExpanded, item }">
        <div class="d-flex justify-space-between">
          <div class="status-icon-container">
            <TooltipAction bottom :message="getMessage(item)" :zIndex="30">
              <template #activator>
                <v-icon :color="getColor(item)">$statusIcon</v-icon>
              </template>
            </TooltipAction>
          </div>
          <v-icon :disabled="loading" @click="expand(!isExpanded)">$cornerDown</v-icon>
        </div>
      </template>
  
      <template #expanded-item="{ headers, item }">
        <td class="cardChildBg pa-0" :colspan="headers.length">
          <CallLogTabs
            :events="item['formatted_events']"
            :tags="item.tags"
          />
        </td>
      </template>
    </v-data-table>

    <TablePagination
      :loading="loading"
      :dataPagination="dataPagination"
      @input="changePage"
    />

    <div :class="pagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    
    <v-slide-x-reverse-transition>
      <AudioPlayer
        v-model="open"
        v-show="open"
        :callerNumber="callerNumber"
        :file="audioFile"
        autoPlay
        @closePlayer="closePlayer"
      />
    </v-slide-x-reverse-transition>
  
    <DialogConfirm
      v-model="confirmDialog"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="confirmationIsLoading"
      @actionSuccess="actionSuccess"
    />
    
    <DialogAdjustCallPayment
      v-model="adjustCallPaymentDialog"
      :dataCall="editCall"
      @actionSuccess="updateCallLogItem"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import currency from '@/mixins/currency'

import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import SelectOnPage from '@/components/select/SelectOnPage'
import SearchField from '@/components/inputs/SearchField'
import AudioPlayer from '@/components/reporting/AudioPlayer'
import TooltipAction from '@/components/tooltip/TooltipAction'
import CallLogTabs from '@/components/reporting/CallLogTabs'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogAdjustCallPayment from '@/components/dialog/DialogAdjustCallPayment'
import TablePagination from '@/components/pagination/TablePagination'

import { copyInStash, formatCallLogDate, formatDuration, calculateDuration, changeItemsAfterUpdate } from '@/helper/app-helper'
import { sendBlockNumber, getAudioLink, stopLiveCall, stopLiveCallForBuyer } from '@/api/reporting-routes'

import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'CallLogStatistics',
  inheritAttrs: false,
  components: { ActionOutlinedButton, SelectOnPage, SearchField, AudioPlayer, TooltipAction, CallLogTabs, DialogConfirm, DialogAdjustCallPayment, TablePagination },
  mixins: [ currency ],
  props: {
    items: {
      type: Array,
      required: true
    },
    dataPagination: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    csvIsLoading: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    timezoneId: {
      type: Number | String,
      default: null
    },
  },
  data: () => ({
    open: false,
    confirmDialog: false,
    adjustCallPaymentDialog: false,
    confirmationIsLoading: false,
    search: null,
    options: {},
    expanded: [],
    statusColor: [
      { id: 1, color: 'yellowLight' },
      { id: 2, color: 'primary' },
      { id: 3, color: 'yellow' },
      { id: 4, color: 'pink' },
    ],
    actionName: null,
    selectedItem: null,
    recordId: null,
    audioFile: null,
    callerNumber: '',
    editCall: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    hangingUpCalls: [],
  }),
  computed: {
    ...mapState({
      timezones: state => state.timezones,
      role: state => state.auth.role,
      permissions: state => state.auth.permissions,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
      isVendor: authTypes.getters.IS_VENDOR,
      isBuyer: authTypes.getters.IS_BUYER,
    }),
    checkAbilityToDownload() {
      return this.isManager || this.permissions['download_reports']
    },
    timeZoneName() {
      const currentTZ = this.timezones.find(t => t.id === this.timezoneId)

      return currentTZ ? currentTZ.php : undefined
    },
    pagination() {
      return Object.assign({}, this.dataPagination)
    },
    headers() {
      switch (this.role) {
        case 'Manager':
          return [
            { text: '', value: 'data-table-expand', sortable: false },
            { text: 'Call Date', value: 'callDate', sort: 'byDate' },
            { text: 'Campaign', value: 'campaign_name', sort: 'byCampaign' },
            { text: 'Vendor', value: 'publisher_name', sort: 'byVendor' },
            { text: 'Caller ID', value: 'caller_number', sort: 'byCaller' },
            { text: 'Dialed', value: 'dialed', sort: 'byDialed' },
            { text: 'Dupe', value: 'dupe', align: 'center', sort: 'byDupe' },
            { text: 'Destination', value: 'target_name', sort: 'byDestination' },
            { text: 'Revenue', value: 'revenue', align: 'center', sort: 'byRevenue' },
            { text: 'Payout', value: 'payout', align: 'center', sort: 'byPayout' },
            { text: 'TTC', value: 'ttc', align: 'center', sort: 'byTimeToConnect' },
            { text: 'Duration', value: 'duration', align: 'center', sort: 'byDuration' },
            { text: 'Hangup', value: 'hangup', align: 'center', sort: 'byHangup' },
            { text: 'Status', value: 'status_id', class: 'minWidth--150', align: 'center', sort: 'byStatus' },
            { text: 'Rec.', value: 'record', align: 'center', sortable: false },
            { text: 'Actions', value: 'actions', align: 'right', width: 130, sortable: false },
          ]
        case 'Vendor':
          return [
            { text: '', value: 'data-table-expand', sortable: false },
            { text: 'Call Date', value: 'callDate', sort: 'byDate' },
            { text: 'Campaign', value: 'campaign_name', sort: 'byCampaign' },
            { text: 'Vendor', value: 'publisher_name', sort: 'byVendor' },
            { text: 'Caller ID', value: 'caller_number', sort: 'byCaller' },
            { text: 'Dialed', value: 'dialed', sort: 'byDialed' },
            { text: 'Dupe', value: 'dupe', align: 'center', sort: 'byDupe' },
            { text: 'Revenue', value: 'revenue', align: 'center', sort: 'byRevenue' },
            { text: 'Duration', value: 'duration', align: 'center', sort: 'byDuration' },
            { text: 'Status', value: 'status_id', class: 'minWidth--150', align: 'center', sort: 'byStatus' },
            { text: 'Rec.', value: 'record', align: 'center', sortable: false },
            { text: 'Actions', value: 'actions', align: 'right', width: 130, sortable: false },
          ]
        case 'Buyer':
          return [
            { text: '', value: 'data-table-expand', sortable: false },
            { text: 'Call Date', value: 'callDate', sort: 'byDate' },
            { text: 'Caller ID', value: 'caller_number', sort: 'byCaller' },
            { text: 'Destination', value: 'target_name', sort: 'byDestination' },
            { text: 'Payout', value: 'payout', align: 'center', sort: 'byPayout' },
            { text: 'Duration', value: 'duration', align: 'center', sort: 'byDuration' },
            { text: 'Status', value: 'status_id', class: 'minWidth--150', align: 'center', sort: 'byStatus' },
            { text: 'Rec.', value: 'record', align: 'center', sortable: false },
            { text: 'Actions', value: 'actions', align: 'right', width: 130, sortable: false },
          ]
        default: return []
      }
    },
    checkStopCall() {
      const hungUpPermission = !!this.permissions?.hangup_calls

      return !this.isVendor && !(this.isBuyer && !hungUpPermission)
    },
    blockNumberPermission() {
      return this.isManager || !!this.permissions?.block_numbers
    },
  },
  methods: {
    changeSearch() {
      this.$emit('changeSearch', this.search)
    },
    checkHangingUpCall(callId) {
      return this.hangingUpCalls.some(c => c === callId)
    },
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    getFormatDate(date) {
      return formatCallLogDate(date)
    },
    getTtc({ ttc }) {
        return formatDuration(ttc)
    },
    getDuration(call) {
      if (!this.loading) {
        if (!!call.live) {
          const duration = calculateDuration(call['created_at'], this.timeZoneName)
          return formatDuration(duration)
        } else {
          return formatDuration(call.duration)
        }
      }
    },
    getColor(call) {
      if (!!call.live) {
        return this.statusColor[0].color
      } else {
        if (call.status) {
          const currentStatus = this.statusColor.find(item => item.id === call.status.id)
          return currentStatus ? currentStatus.color : ''
        } else {
          return ''
        }
      }
    },
    getMessage(call) {
      if (!!call.live) {
        return 'Live'
      } else {
        if (call.status) {
          return call.status.name
        } else {
          return ''
        }
      }
    },
    getStatus(call) {
      if (call.status) {
        return call.status.name
      } else {
        return ''
      }
    },
    refresh() {
      this.$emit('update:dataPagination', Object.assign({}, this.dataPagination, { page: 1 }))
      this.$emit('pageParamsChanged')
    },
    getHangupMessage(hangup) {
      return hangup ? hangup.name : ''
    },
    getHangupIcon(hangup) {
      return hangup ? `$${hangup.name.toLowerCase()}Icon` : ''
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.$emit('update:dataPagination', Object.assign({}, this.dataPagination, { page }))
        this.$emit('pageParamsChanged')
      }
      this.$vuetify.goTo('#call-log')
    },
    callLogSetFilter({ filter, filterName, name, id }) {
      const newFilter = {
        [filter]: {
          id, name, filterName
        }
      }
      this.$emit('update:filters', Object.assign({}, this.filters, newFilter))
    },
    async openPlayer(item) {
      this.audioFile = null
      this.callerNumber = null
      this.recordId = null
      
      const { success, payload, message } = await getAudioLink(item.uuid)
      
      if (success) {
        this.audioFile = payload
        this.callerNumber = item['caller_number']
        this.recordId = item.id
        this.open = true
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    closePlayer() {
      this.open = false
      this.audioFile = null
      this.callerNumber = null
      this.recordId = null
    },
    exportCSV() {
      this.$emit('downloadCallLogCSV')
    },
    openStopCall(item) {
      this.actionName = 'stopLiveCall'
      this.selectedItem = item
      this.dialogTitle = 'Stop live call'
      this.dialogSubTitle = ''
      this.dialogMessage = `Are you sure you want to stop this call?`
      this.confirmDialog = true
    },
    openBlockNumber(item) {
      this.actionName = 'blockNumber'
      this.selectedItem = item
      this.dialogTitle = 'Block number'
      this.dialogSubTitle = `${item['caller_number']}`
      this.dialogMessage = `Are you sure you want to block this number for this campaign - ${item['campaign_name']}?`
      this.confirmDialog = true
    },
    actionSuccess() {
      this[this.actionName]()
    },
    async stopLiveCall() {
      this.confirmationIsLoading = true

      const { success, payload, message } = this.isManager
        ? await stopLiveCall(this.selectedItem.uuid)
        : await stopLiveCallForBuyer(this.selectedItem.uuid)

      if (success) {
        !!message && this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.hangingUpCalls.push(this.selectedItem.uuid)
        // this.updateAllItems(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.confirmationIsLoading = false
    },
    async blockNumber() {
      this.confirmationIsLoading = true
      
      const { success, message } = await sendBlockNumber({
        number: this.selectedItem['caller_number'],
        campaign: this.selectedItem.campaign.slug
      })
      
      if (success) {
        !!message && this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.updateAllItemsAfterBlock(this.selectedItem['caller_number'])
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.confirmationIsLoading = false
    },
    updateAllItems(updatedCall) {
      this.$emit('update:items', changeItemsAfterUpdate(this.items, updatedCall))
    },
    updateAllItemsAfterBlock(number) {
      const updateItems = this.items.map(item => ({
        ...item,
        'caller_in_bl': item['caller_number'] === number ? true : item['caller_in_bl']
      }))
      this.$emit('update:items', updateItems)
    },
    openAdjustCallPayment(item) {
      this.editCall = item
      this.adjustCallPaymentDialog = true
    },
    updateCallLogItem(updatedItem) {
      const index = this.items.findIndex(item => item.uuid === updatedItem.uuid)
      if (index !== null && index > -1) {
        const updateItems = this.items.slice()
        updateItems[index] = Object.assign({}, updateItems[index], updatedItem)
        this.$emit('update:items', updateItems)
      }
    },
    copyName(name) {
      copyInStash(name)
      this.$notify({ type: 'info-bg', text: 'Copy successful' })
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.$emit('sortBy', {[sortBy.sort]: sortOptions.sortDesc[0] ? 'asc' : 'desc'})
        } else {
          this.$emit('sortBy', null)
        }
      },
    },
    loading: {
      handler(loading) {
        if (!loading) {
          this.hangingUpCalls = []
        }
      }
    },
  },
}
</script>
