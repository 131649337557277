<template>
  <v-row no-gutters class="pt-7 pt-sm-10 pt-xl-5">
    <HorizontalBarChart
      v-if="!visiblePieChartHideBarChart"
      :data="totalData"
      :isLoading="isLoading"
    />
    
    <StackBarChart
      :timeGroupId="timeGroupId"
      :colOption="9"
      :data="stackBarData"
      :isLoading="isLoading"
    />
    
    <PieChart
      v-if="visiblePieChartHideBarChart"
      :data="totalData"
      :isLoading="isLoading"
    />
  </v-row>
</template>

<script>
import StackBarChart from '@/components/charts/StackBarChart'
import HorizontalBarChart from '@/components/charts/HorizontalBarChart'
import PieChart from '@/components/charts/PieChart'

export default {
  name: 'ChartReporting',
  inheritAttrs: false,
  components: { StackBarChart, HorizontalBarChart, PieChart },
  props: {
    chartData: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    timeGroupId: {
      type: String,
      required: true
    },
  },
  data: () => ({
    details: {
      noAnswer: [],
      converted: [],
      notConverted: [],
    },
  }),
  computed: {
    visiblePieChartHideBarChart() {
      return this.$vuetify.breakpoint.width > 1012
    },
    allConvertedCalls() {
      return this.details.converted.reduce((value, item) => value + item, 0)
    },
    allNoConvertedCalls() {
      return this.details.notConverted.reduce((value, item) => value + item, 0)
    },
    allNoAnswerCalls() {
      return this.details.noAnswer.reduce((value, item) => value + item, 0)
    },
    stackBarData() {
      return this.chartData.map(item => ({
        ...item,
        'not_converted': item.total - item.converted - item['no_answer']
      }))
    },
    totalData() {
      return [{
        name: 'Converted',
        value: this.allConvertedCalls
      }, {
        name: 'Not Converted',
        value: this.allNoConvertedCalls
      }, {
        name: 'No Answer',
        value: this.allNoAnswerCalls
      }]
    },
  },
  methods: {
    parseProps() {
      if (this.chartData.length) {
        this.clearData()
        this.details = this.chartData.reduce((data, item) => {
          data.converted.push(item.converted)
          data.noAnswer.push(item['no_answer'])
          data.notConverted.push(item.total - item.converted - item['no_answer'])
          return data
        }, {
          noAnswer: [],
          converted: [],
          notConverted: [],
        })
      }
    },
    clearData() {
      Object.keys(this.details).forEach(name => {
        this.details[name] = []
      })
    },
  },
  watch: {
    isLoading: {
      handler(loading) {
        if (!loading) {
          this.parseProps()
        }
      },
    },
  },
}
</script>
