<template>
  <div class="aggregate-container" v-if="aggregatedGroups">
    <div class="d-flex align-center justify-space-between pt-8 pt-sm-10 pb-4 pb-sm-5">
      <div class="d-flex align-center flex-grow-1">
        <div class="font-page-header">calls</div>
      </div>
      
      <div class="d-flex align-center">
        <ActionOutlinedButton
          v-if="checkAbilityToDownload"
          :loading="csvIsLoading"
          @click="exportCSV"
        >Export CSV</ActionOutlinedButton>
      </div>
    </div>
    
    <v-tabs
      v-if="aggregatedGroups.length"
      class="aggregatedTabs"
      :class="{ 'border-bottom': !items.length }"
      v-model="currentGroupTab"
      background-color="transparent"
      mobile-breakpoint="0"
      height="62"
      show-arrows
    >
      <v-tab
        v-for="(item, index) in aggregatedGroups"
        class="font-medium"
        :class="item.id === 'byTag' ? 'px-0' : 'px-10'"
        :key="item.id"
        :ripple="false"
        :disabled="loading"
      >
        <MenuAggregatedByTags
          v-if="item.id === 'byTag'"
          @changeTag="changeTag"
        >
          <template #title="{ hover }">
            <span :class="index === currentGroupTab ? 'primary--text' : hover ? 'tabHoverColor--text' : ''">{{ item.name }}</span>
          </template>
          
          <template #icon="{ hover }">
            <v-icon class="ml-2" size="10" :color="index === currentGroupTab ? 'primary' : hover ? 'tabHoverColor' : ''">$arrowDownStrong</v-icon>
          </template>
        </MenuAggregatedByTags>
        
        <span v-else>{{ item.name }}</span>
      </v-tab>
    </v-tabs>
    
    <v-data-table
      v-if="headers.length"
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :custom-sort="customSort"
      class="elevation-0 borderNone tableBg rounded-20 borderAggregatedTable"
      outlined
      disable-pagination
      hide-default-footer
      mobile-breakpoint="0"
      :loader-height="2"
      :header-props="{ sortIcon: '$sortDownIcon' }"
    >
      <template #header.Tag-Value>
        <span>{{ getTagNameHeader }}</span>
      </template>
      
      <template #item.Campaign="{ item }">
        <span class="text-no-wrap" v-if="item.Campaign === 'Totals'">{{ item.Campaign }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'campaign', filterName: 'Campaign', name: item.Campaign, id: item.Campaign })"
        >
          <span class="text-no-wrap">{{ item.Campaign }}</span>
        </v-btn>
      </template>
      
      <template #item.Vendor="{ item }">
        <span class="text-no-wrap" v-if="item.Vendor === 'Totals'">{{ item.Vendor }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'publisher', filterName: 'Vendor', name: item.Vendor, id: item.Vendor })"
        >
          <span class="text-no-wrap">{{ item.Vendor }}</span>
        </v-btn>
      </template>
      
      <template #item.Dialed_#="{ item }">
        <span class="text-no-wrap" v-if="item['Dialed #'] === 'Totals'">{{ item['Dialed #'] }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'number', filterName: 'Number', name: item['Dialed #'].number, id: item['Dialed #'].id })"
        >
          <span class="text-no-wrap">{{ item['Dialed #'].number }}</span>
        </v-btn>
      </template>

      <template #item.Traffic_Source="{ item }">
        <span class="text-no-wrap" v-if="item['Traffic Source'] === 'Totals'">{{ item['Traffic Source'] }}</span>

        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'trafficSource', filterName: 'Traffic Sources', name: item['Traffic Source'].name, id: item['Traffic Source'].id })"
        >
          <span class="text-no-wrap">{{ item['Traffic Source'].name }}</span>
        </v-btn>
      </template>
      
      <template #item.Destination="{ item }">
        <span class="text-no-wrap" v-if="item['Destination'] === 'Totals'">{{ item['Destination'] }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'target', filterName: 'Destination', name: item['Destination'], id: item['Destination'] })"
        >
          <span class="text-no-wrap">{{ item['Destination'] }}</span>
        </v-btn>
      </template>
      
      <template #item.Buyer="{ item }">
        <span class="text-no-wrap" v-if="item['Buyer'] === 'Totals'">{{ item['Buyer'] }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'buyer', filterName: 'Buyer', name: item['Buyer'], id: item['Buyer'] })"
        >
          <span class="text-no-wrap">{{ item['Buyer'] }}</span>
        </v-btn>
      </template>
      
      <template #item.Tag-Value="{ item }">
        <span class="text-no-wrap" v-if="item['Tag-Value'] === 'Totals'">{{ item['Tag-Value'] }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'tags', filterName: 'Tag', name: item['Tag-Value'], id: item['Tag-Value'] })"
        >
          <span class="text-no-wrap">{{ item['Tag-Value'] }}</span>
        </v-btn>
      </template>
      
      <template #item.Incoming="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'incoming', filterName: 'Incoming', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['Incoming'] }}
        </v-btn>
      </template>
      
      <template #item.Connected="{ item }">
        <span v-if="aggregatedGroups.length !== 1 && aggregatedGroups.length - 1 === currentGroup">{{ item['Connected'] }}</span>
        
        <v-btn
          v-else
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'connected', filterName: 'Connected', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['Connected'] }}
        </v-btn>
      </template>
      
      <template #item.Converted="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'converted', filterName: 'Converted', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['Converted'] }}
        </v-btn>
      </template>
      
      <template #item.Qualified="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'qualified', filterName: 'Qualified', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['Qualified'] }}
        </v-btn>
      </template>

      <template #item.Paid="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'paid', filterName: 'Paid', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['Paid'] }}
        </v-btn>
      </template>
      
      <template #item.No_Connect="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'noConnect', filterName: 'No Connect', name: 'Yes', id: true, filterTo: item})"
        >
          {{ item['No Connect'] }}
        </v-btn>
      </template>
      
      <template #item.Dupe="{ item }">
        <v-btn
          class="px-0"
          text
          plain
          min-width="20"
          @click="aggregatedDataSetFilter({ filter: 'dupe', filterName: 'Dupe', name: 'Yes', id: 1, filterTo: item})"
        >
          {{ item['Dupe'] }}
        </v-btn>
      </template>
      
      <template #item.Date="{ item }">
        <span class="text-no-wrap">{{ item.Date }}</span>
      </template>
      
      <template #item.Payout="{ item }">
        {{ currency(item['Payout']) }}
      </template>
      
      <template #item.Revenue="{ item }">
        {{ currency(item['Revenue']) }}
      </template>
      
      <template #item.Cost="{ item }">
        {{ currency(item['Cost'], 'USD', 3) }}
      </template>
      
      <template #item.Profit="{ item }">
        {{ currency(item['Profit'], 'USD', 3) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import MenuAggregatedByTags  from '@/components/menu/MenuAggregatedByTags'

import currency from '@/mixins/currency'

import { customSorting, copyObject } from '@/helper/app-helper'
import { mapGetters, mapState } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'AggregatedStatistics',
  inheritAttrs: false,
  components: { ActionOutlinedButton, MenuAggregatedByTags },
  mixins: [ currency ],
  props: {
    aggregatedGroups: {
      type: Array,
      required: true
    },
    currentGroup: {
      type: Number | String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    csvIsLoading: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    options: {},
    headers: [],
    groupDesc: false,
    numericSortingTypes: ['Connected', 'Converted', 'Cost', 'Dupe', 'Incoming', 'Live', 'No Connect', 'Payout', 'Profit', 'Qualified', 'Revenue'],
    timeSortingTypes: ['TCL', 'ACL'],
    tagParams: null,
    groupParams: null,
    tagFilter: null,
  }),
  computed: {
    ...mapState({
      permissions: state => state.auth.permissions,
    }),
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    checkAbilityToDownload() {
      return this.isManager || this.permissions['download_reports']
    },
    currentGroupTab: {
      get() {
        return this.currentGroup
      },
      set(group) {
        const groupBy = this.aggregatedGroups[group].id
        this.groupParams = groupBy === 'byTag'
          ? { groupBy, ...this.tagParams}
          : { groupBy }
        this.$emit('changeGroup', this.groupParams)
        this.$emit('update:currentGroup', group)
      },
    },
    getTagNameHeader() {
      if (this.groupParams?.AggTagGroup && this.groupParams?.AggTagName) {
        return `${this.groupParams?.AggTagGroup}: ${this.groupParams?.AggTagName}`
      } else {
        return ''
      }
    },
  },
  methods: {
    changeTag({ tagParams, tagFilter}) {
      this.tagParams = tagParams
      this.tagFilter = tagFilter
      
      const tabIndex = this.aggregatedGroups.findIndex(item => item.id === 'byTag')
      
      if (tabIndex + 1) {
        this.currentGroupTab = tabIndex
      }
    },
    parseProps() {
      if (this.items.length) {
        const newHeaders = []
        Object.keys(this.items[0]).forEach((name, index) => {
          const header = {
            text: name, value: name.split(' ').join('_')
          }
          if (index) {
            header.align = 'center'
          }
          newHeaders.push(header)
        })
        this.headers = newHeaders
      }
    },
    mergeTagFilterWithId(tagFilter, id) {
      const tagGroup = Object.keys(tagFilter)[0]
      const tagName = Object.keys(tagFilter[tagGroup])[0]
      let newTagFilter = null

      tagFilter[tagGroup][tagName].id = id
      tagFilter[tagGroup][tagName].name = tagFilter[tagGroup][tagName].name + id

      newTagFilter = this.filters?.tags ? this.filters.tags : {}

      if (newTagFilter.hasOwnProperty(tagGroup)) {
        newTagFilter[tagGroup] = Object.assign(newTagFilter[tagGroup], tagFilter[tagGroup])
      } else {
        newTagFilter[tagGroup] = tagFilter[tagGroup]
      }
      
      return newTagFilter
    },
    aggregatedDataSetFilter({ filter, filterName, name, id, filterTo = null }) {
      const newFilter = {
          [filter]: filter === 'tags'
            ? this.mergeTagFilterWithId(copyObject(this.tagFilter), id)
            : { id, name, filterName }
        }
      if (!!filterTo) {
        const currentGroupName = this.aggregatedGroups[this.currentGroup].name
        if (filterTo[currentGroupName] !== 'Totals') {
          switch (currentGroupName) {
            case 'Campaign': newFilter['campaign'] = {
              filterName: 'Campaign',
              id: filterTo[currentGroupName],
              name: filterTo[currentGroupName]
            }
              break
            case 'Vendor': newFilter['publisher'] = {
              filterName: 'Vendor',
              id: filterTo[currentGroupName],
              name: filterTo[currentGroupName]
            }
              break
            case 'Dialed #': newFilter['number'] = {
              filterName: 'Number',
              id: filterTo[currentGroupName].id,
              name: filterTo[currentGroupName].number
            }
              break
            case 'Destination': newFilter['target'] = {
              filterName: 'Destination',
              id: filterTo[currentGroupName],
              name: filterTo[currentGroupName]
            }
              break
            case 'Buyer': newFilter['buyer'] = {
              filterName: 'Buyer',
              id: filterTo[currentGroupName],
              name: filterTo[currentGroupName]
            }
              break
            case 'Traffic Source': newFilter['trafficSource'] = {
              filterName: 'Traffic Sources',
              id: filterTo[currentGroupName].id,
              name: filterTo[currentGroupName].name
            }
              break
            case 'Tags':
              newFilter['tags'] = this.mergeTagFilterWithId(copyObject(this.tagFilter), filterTo['Tag-Value'])
              break
          }
        }
      }
      this.$emit('update:filters', Object.assign({}, this.filters, newFilter))
    },
    exportCSV() {
      this.$emit('downloadAggCSV')
    },
    customSort(items, index, isDesc) {
      if (!index.length) {
        return items
      }
      
      const total = items.pop()
      const fieldName = index[0].split('_').join(' ')
      const type = this.getFieldType(fieldName)
      items.sort((a, b) => {
        if(typeof a[fieldName] !== 'undefined') {
          return customSorting(a[fieldName], b[fieldName], !!isDesc[0], type)
        }
      })
      items.push(total)
      return items
    },
    getFieldType(name) {
      if (this.numericSortingTypes.some(item => item === name)) {
        return 'number'
      }
      
      if (this.timeSortingTypes.some(item => item === name)) {
        return 'time'
      }
      
      return 'string'
    },
  },
  watch: {
    items: {
      handler() {
        this.parseProps()
      },
    },
  },
}
</script>
