<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="560px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
<!--      <v-row no-gutters>-->
<!--        <v-col cols="12" sm="5" class="pb-8 pr-11">-->
<!--          <div class="font-large-title text-uppercase dialogTitle&#45;&#45;text text-sm-right">-->
<!--            Send pin code-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-form class="pb-10 pt-5" ref="form" v-model="validForm" @submit.prevent="submitForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Security Pin Code</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="pinCode"
            ></portal-target>
          </v-col>

          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <InputPassword
              v-model="formData.pin"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="pinCode" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputPassword>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>

        <ActionButton class="ml-5 ml-sm-11" :loading="loading" @click="submitForm">Send</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import InputPassword from '@/components/inputs/InputPassword'
import SwitchLg from '@/components/controls/SwitchLg'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { checkCallLogPin } from '@/api/security-routes'

export default {
  name: 'DialogSendCallLogPin',
  inheritAttrs: false,
  components: { InputPassword, SwitchLg, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      pin: null,
    },
    rules: []
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true

        const { success, payload, message } = await checkCallLogPin(this.formData)

        if (success) {
          this.$emit('successPin', { payload, pin: this.formData.pin })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
        this.loading = false
      }
    },
  },
}
</script>